.list-counter-item {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-left: 2rem;
}

.circle-number {
  position: absolute;
  left: 0;
  line-height: 1.5rem; /* Adjust based on your font size */
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px; /* Full circle */
  border-color: black;
  border-width: 0.08rem;
  background-color: white;
  color: black;
}

.circle-number-done {
  border-color: gray;
  border-width: 0.09em;
  color: gray;
  font-weight: semi-bold;
}

.circle-number-in-progress {
  border-width: 0;
  background-color: #16A34A; /* Tailwind's green-600 */
  color: white;
  font-weight: semi-bold;
}

.item-text {
  line-height: 1.5rem; /* Match this with the circle-number's line-height */
}
